import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

type CardPropsType = {
  children: React.ReactNode;
  className?: string;
};

export const GridCard = ({
  children,
  bgDirection = "tr",
}: {
  children: React.ReactNode;
  bgDirection?: "tl" | "tr";
}) => {
  return (
    <Card className="overflow-hidden">
      <div className="relative">
        <div
          className={clsx(
            "absolute inset-0 z-0 h-full w-full from-blue-50/100 to-blue-50/10",
            bgDirection === "tr" && "bg-gradient-to-tr",
            bgDirection === "tl" && "bg-gradient-to-tl",
          )}
        />
        <div className="absolute inset-0 z-0 h-full w-full rotate-0 bg-grid-blue-50/60" />

        <div className="isolate">{children}</div>
      </div>
    </Card>
  );
};

export default function Card({ children, className }: CardPropsType) {
  return (
    <div
      className={twMerge(
        clsx(
          "w-full rounded-md bg-white shadow outline outline-1 outline-gray-800/[20%]",
          className,
        ),
      )}
    >
      {children}
    </div>
  );
}
